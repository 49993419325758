@import 'sass';

.BioSection {
	position: relative;
	padding: 0 !important;

	.HeaderContainer,
	.SocialSection {
		@include flex-col-center;
		width: 100%;
		height: fit-content;
		position: relative;
		max-height: 800px;
		overflow: hidden;

		img {
			width: 100%;
		}
	}

	.BottomUpGradient,
	.TopDownGradient {
		width: 100%;
		height: 100%;
		position: absolute;
		background: rgb(12, 12, 12);
		top: 0;
		left: 0;
	}

	.BottomUpGradient {
		background: linear-gradient(0deg, rgba(12, 12, 12, 1) 0%, rgba(12, 12, 12, 0) 73%, rgba(12, 12, 12, 0) 100%);
	}
	.TopDownGradient {
		background: linear-gradient(180deg, rgba(12, 12, 12, 1) 0%, rgba(12, 12, 12, 0) 73%, rgba(12, 12, 12, 0) 100%);
	}

	.Content {
		z-index: 30;
		padding: 0;
		h1 {
			font-size: 4.5rem;
			letter-spacing: 0.05rem;
			margin-bottom: 13px;
		}

		.BodyLarge {
			font-size: 1.6rem;
			line-height: 1.9rem;
			letter-spacing: 0.03rem;
			margin-bottom: 12px;
		}

		.BodySmall {
			font-size: 1.3rem;
			line-height: 1.8rem;
			letter-spacing: 1px;
			font-weight: 200;
			margin-bottom: 80px;
		}
	}
	.ButtonWrapper {
		@include flex-col-center;
		top: 0;
		position: absolute;
		width: 100%;
		z-index: 20;
		margin: 0 auto 0 12.5% !important;
	}

	.ReelWrapper {
		z-index: 30;
		width: 100%;
		overflow: hidden;
		position: absolute;
		bottom: 30px;
		left: 0;
	}

	@media only screen and (max-width: 767px) {
		.ButtonWrapper {
			margin: 0 !important;
			padding: 0 5%;
		}
	}

	@media only screen and (max-width: 576px) {
		.Content {
			padding: 0;

			h1 {
				font-size: 2.4rem;
			}

			.BodyLarge {
				font-size: 1.3rem;
				line-height: 1.6rem;
			}

			.BodySmall {
				font-size: 1.1rem;
				line-height: 1.4rem;
				margin-bottom: 45px;
			}
		}

		.BGPic {
			width: 150% !important;
			margin-left: -20% !important;
		}
	}
}
