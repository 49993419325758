@import 'sass';

.Wrapper {
	position: relative;
	width: 100%;

	.HeaderPic {
		position: absolute;
		width: 100%;
		height: 70vh;
		max-height: 800px;
		min-height: 300px;
		top: 0;
		left: 0;
		background-size: cover;
		background-position: center;
	}

	.HeaderText {
		margin: 0;
		font-size: 2.2rem;
		font-weight: 300;
	}

	.StatBarOuter {
		background-color: map-get($theme-colors, color_themePrimaryOpaq);
		z-index: 1;
		width: inherit;
		position: inherit;

		.StatBarInner {
			@include flex-center-start;

			.Stat {
				p {
					font-size: 2.5rem;
					margin: 0;

					span {
						font-size: 1.25rem;
					}
				}

				&:last-of-type {
					margin-left: 30px;
				}
			}
		}
	}
}
