@import 'sass';

.Wrapper {
	position: absolute;
	z-index: inherit;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;

	&[data-type='normal'] {
		background-color: rgba(12, 12, 12, 0.8);
	}
	&[data-type='light'] {
		background: linear-gradient(90deg, rgba(0, 0, 0, 0.207) 25%, rgba(0, 0, 0, 0) 100%),
			linear-gradient(0deg, rgba(25, 36, 46, 0.49), rgba(25, 36, 46, 0.49));
	}

	&[data-type='from-bottom'] {
		background: linear-gradient(0deg, rgba(12, 12, 12, 1) 0%, rgba(12, 12, 12, 0) 45%, rgba(12, 12, 12, 0) 100%);
	}

	&[data-type='from-top'] {
		background: linear-gradient(0deg, rgba(12, 12, 12, 1) 100%, rgba(12, 12, 12, 0) 45%, rgba(12, 12, 12, 0) 0%);
	}
}
