@import 'sass';

.Wrapper {
	@include flex-center-center;

	svg {
		padding: 0;
		margin: 0;
	}

	&[data-size='sm'] {
		width: 30px;
		height: 30px;

		svg {
			font-size: 20px;
		}
	}

	&[data-size='md'] {
		width: 50px;
		height: 50px;

		svg {
			font-size: 40px;
		}
	}

	&[data-size='lg'] {
		width: 60px;
		height: 60px;

		svg {
			font-size: 50px;
		}
	}
}
